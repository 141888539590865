<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Account</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item">Charging & Billing</li>
              <li class="breadcrumb-item">Accounting</li>
              <li class="breadcrumb-item active">Account</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card" id="department-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{ name: 'CBSAccount' }">
                <i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="name" class="control-label">Client Reference Id</label>
              <input type="text" id="code" name="code" readonly v-model="value.code" class="form-control code" />
            </div>
            <div class="form-group required">
              <label for="name" class="control-label">Account name</label>
              <input type="text" id="name" name="name" v-bind:class="{ 'has-error': errors.has('name') }" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name" />
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first("name") }}
              </div>
            </div>
            <div class="form-group">
              <label for="name" class="control-label">Description</label>
              <input type="text" id="description" name="description" v-bind:class="{ 'has-error': errors.has('description') }" v-model="value.description" class="form-control description" placeholder="Input Description" />
              <div class="help text-danger" v-show="errors.has('description')">
                {{ errors.first("description") }}
              </div>
            </div>
            <div class="form-group required">
              <label class="control-label" for="general_ledger_account_id">General Ledger Account</label>
              <multiselect name="general_ledger_account_id" v-model="value.general_ledger_account_id" :options="
                  generalLedgerAccount.map((ledgerAccount) => ledgerAccount.id)
                " :custom-label="
                  (opt) => generalLedgerAccount.find((x) => x.id == opt).name
                " placeholder="Pick General Ledger Account" :searchable="true" :multiple="false" v-bind:class="{
                  'has-error': errors.has('general_ledger_account_id'),
                }" v-validate="'required'" class="general_ledger_account_id"></multiselect>
              <div class="help text-danger" v-show="errors.has('general_ledger_account_id')">
                {{ errors.first("general_ledger_account_id") }}
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="parent_account_id">Parent Account</label>
              <multiselect name="parent_account_id" v-model="value.parent_account_id" :options="parentAccount.map((account) => account.id)" :custom-label="
                  (opt) => parentAccount.find((x) => x.id == opt).name
                " placeholder="Pick Parent Account" :searchable="true" :multiple="false" v-bind:class="{
                  'has-error': errors.has('parent_account_id'),
                }" class="parent_account_id">
              </multiselect>
              <div class="help text-danger" v-show="errors.has('parent_account_id')">
                {{ errors.first("parent_account_id") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="product_id" class="control-label">Product ID</label>
              <input type="text" id="product_id" name="product_id" v-bind:class="{ 'has-error': errors.has('product_id') }" v-validate="'required'" v-model="value.product_id" class="form-control product_id" placeholder="Product ID" />
              <div class="help text-danger" v-show="errors.has('product_id')">
                {{ errors.first("product_id") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="status" class="control-label">Status</label>
              <select class="form-control" name="status" v-bind:class="{ 'has-error': errors.has('status') }" v-validate="'required'" id="status" v-model="value.status">
                <option :value="1">Active</option>
                <option :value="0">Inactive</option>
              </select>
              <div class="help text-danger" v-show="errors.has('status')">
                {{ errors.first("status") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="enable_payments" class="control-label">Enable payment</label>
              <select class="form-control" name="enable_payments" v-bind:class="{ 'has-error': errors.has('enable_payments') }" v-validate="'required'" id="enable_payments" v-model="value.enable_payments">
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
              <div class="help text-danger" v-show="errors.has('enable_payments')">
                {{ errors.first("enable_payments") }}
              </div>
            </div>
            <div class="form-group">
              <label for="system_account" class="control-label">System Account</label>
              <select class="form-control" name="system_account" v-bind:class="{ 'has-error': errors.has('system_account') }" id="system_account" v-model="value.system_account">
                <option value="DEBTORS">DEBTORS</option>
                <option value="CREDITORS">CREDITORS</option>
              </select>
              <div class="help text-danger" v-show="errors.has('system_account')">
                {{ errors.first("system_account") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="debit_balance" class="control-label">Debit</label>
              <input type="number" id="debit_balance" name="debit_balance" v-bind:class="{ 'has-error': errors.has('debit_balance') }" v-validate="'required'" v-model="value.debit_balance" class="form-control debit_balance" placeholder="Debit" />
              <div class="help text-danger" v-show="errors.has('debit_balance')">
                {{ errors.first("debit_balance") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="credit_balance" class="control-label">Credit</label>
              <input type="number" id="credit_balance" name="credit_balance" v-bind:class="{ 'has-error': errors.has('credit_balance') }" v-validate="'required'" v-model="value.credit_balance" class="form-control credit_balance" placeholder="Credit" />
              <div class="help text-danger" v-show="errors.has('credit_balance')">
                {{ errors.first("credit_balance") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="net_balance" class="control-label">Net</label>
              <input type="number" id="net_balance" name="net_balance" v-bind:class="{ 'has-error': errors.has('net_balance') }" v-validate="'required'" v-model="value.net_balance" class="form-control net_balance" placeholder="Net" />
              <div class="help text-danger" v-show="errors.has('net_balance')">
                {{ errors.first("net_balance") }}
              </div>
            </div>

          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">
              Submit
            </button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CBSAccountingApi, CBSCommonApi } from '@/services/api';
import { uuid } from "vue-uuid";

export default {
  name: 'CBSAccountForm',
  components: {
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {},
    parentAccount: [],
    generalLedgerAccount: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSAccountingApi.updateAccount(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'CBSAccount' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.created_by = this.profile.id
            CBSAccountingApi.storeAccount(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'CBSAccount' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSAccountingApi.showAccount(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getAllAccount: function () {
      CBSCommonApi.getAllAccount().then((response) => {
        this.parentAccount = response
      })
    },
    getAllGeneralLedgerAccount: function () {
      CBSCommonApi.getAllGeneralLedgerAccount().then((response) => {
        this.generalLedgerAccount = response
      })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      } else {
        this.value.code = uuid.v1();
      }
      await this.getAllAccount()
      await this.getAllGeneralLedgerAccount()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

